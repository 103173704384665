const vmMachineStatus = {
	Other : 1,
	Running : 2,
	Off : 3,
	OffForClone : 8,
	Saved : 6,
	Paused : 9,
	Starting : 10,
	Reset: 11,
	Restaring: 12,
	Creating : 32773,
	Pausing : 32776,
	Resuming : 32777,
	FastSaved : 32779,
	FastSaving : 32780,
}

const vmMachineStatusText = {
	"1": "Other",
	"2": "Running",
	"3" : "Off",
	"8" : "Off for clone",
	"6": "Saved",
	"9": "Paused",
	"10": "Starting..",
	"11": "Reset",
	"12": "Restaring..",
	"32773": "Creating..",
	"32776": "Pausing",
	"32777": "Resuming",
	"32779": "Fast Saved",
	"32780": "Fast Saving",
}

const vmMachineStatusColor = {
	"1": "muted",
	"2": "success",
	"3": "danger",
	"8": "danger",
	"6": "primary",
	"9": "warning",
	"10": "primary",
	"11": "primary",
	"12": "primary",
	"32773": "primary",
	"32776": "primary",
	"32777": "primary",
	"32779": "success",
	"32780": "success",
}

const invoiceStatusText = {
	"1": "Invoice inited",
	"2": "Sent to payment agent",
	"3": "Pending",
	"4": "Failed",
	"5": "Successed",
}

const transactionStatusText = {
	"0": "Pending",
	"1": "Proccessed",
}


const colorGpuNodeColor = {
	"bronze": "gradient1",
	"sliver": "gradient1",
	"golden": "gradient2",
	"4x2080ti": "gradient3",
	"platinum": "gradient0",
}

const getGaugeOptions = (labelName, value, unit = '°C') => {
    return {
        series: [{
            type: 'gauge',
            center: ["50%", "70%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: '100%',
            itemStyle: {
                color: () => {
                    if (value >= 90) return '#f00';
                    if (value >= 70) return '#FFA700';
                    return '#006E4E';
                }
            },
            progress: {
                show: true,
                width: 20
            },

            pointer: {
                show: false,
            },
            axisLine: {
                show: false,
                lineStyle: {
                    width: 20,
                    color: [
                        [0.3, '#67e0e3'],
                        [0.7, '#37a2da'],
                        [1, '#f00']
                    ]
                }
            },
            axisTick: {
                distance: -35,
                splitNumber: 5,
                lineStyle: {
                    width: 2,
                    color: '#999'
                }
            },
            splitLine: {
                show: false,
                distance: -63,
                length: 14,
                lineStyle: {
                    width: 3,
                    color: '#999'
                }
            },
            axisLabel: {
                show: false,
                distance: 8,
                color: '#999',
                fontSize: 15
            },
            anchor: {
                show: false
            },
            title: {
                show: true,
                fontSize: 12
            },
            detail: {
                valueAnimation: true,
                width: '60%',
                lineHeight: 40,
                height: 10,
                borderRadius: 8,
                offsetCenter: [0, '-10%'],
                fontSize: 25,
                fontWeight: 'bolder',
                formatter: `{value}${unit}`,
                color: 'auto'
            },
            data: [{
                value: value,
                name: labelName
            }]
        }
        ],
    }
}

export {
	vmMachineStatus, vmMachineStatusText, invoiceStatusText,
	transactionStatusText, colorGpuNodeColor,
    vmMachineStatusColor,
    getGaugeOptions
}
