import { gpuHubCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListTransaction}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    getTotalRecharge() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getTotalRecharge}`,
            withCredentials: true,
        });
    },
    getTotalRechargeByStaff(staffId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getTotalRechargeByStaff.format(staffId)}`,
            withCredentials: true,
        });
    },
    exportListTransaction(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.exportListTransaction}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
        //return `${gpuHubCnf.exportListTransaction}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`;
    },
    updateTransaction(data) {
        return axios({
            method: "POST",
            url: gpuHubCnf.updateTransaction,
            data: data,
            withCredentials: true,
        });
    },
    deleteTransaction(data) {
        return axios({
            method: "POST",
            url: gpuHubCnf.deleteTransaction,
            data: data,
            withCredentials: true,
        });
    },
    getRechargeTestStatistics(pageNumber, requestParam, month, year) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getRechargeTestStatistics}/${month}/${year}?${queryParams}`,
            withCredentials: true,
        });
    },
    getRechargeTestStatisticsDetail(pageNumber, requestParam, userId, staffId) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getRechargeTestStatisticsDetail}/${userId}/${staffId}?${queryParams}`,
            withCredentials: true,
        });
    },
    exportRechargeTestStatistics(month, year) {
        return `${gpuHubCnf.exportRechargeTestStatistics}/${month}/${year}`;
    },

    getListRefundTransaction(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListRefundTransaction}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    addRefundTransaction(data) {
        return axios({
            method: "POST",
            url: gpuHubCnf.addRefundTransaction,
            data: data,
            withCredentials: true,
        });
    },
    markRefunded(data) {
        return axios({
            method: "POST",
            url: gpuHubCnf.markRefunded,
            data: data,
            withCredentials: true,
        });
    },
};