import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import gpuHubTransactionApi from '@/api/gpuHub/gpuHubTransaction';
import { debounce } from 'vue-debounce';
import { transactionStatusText } from "@/constant/vmMachineStatusConst";
import moment from "moment";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBoxWithoutLoading';
import { colorGpuNodeColor } from "@/constant/vmMachineStatusConst";
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    components: {
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBox,
        }),
        addRefundTransaction: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/transactions/AddRefundTransaction.vue"),
            loading: SkeletonBox,
        }),
    },
    watch: {
        ownedTarget(newVal) {
            if (newVal !== null) this.getAllResult(1);
        }
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {},
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            toogleFilter: false,
            elementDetail: null,
            refundModel: null,
            providerSelection: [
                { id: 'BANKING', text: 'BANKING' },
                { id: 'CARD', text: 'CARD' },
                { id: 'PAYPAL', text: 'PAYPAL' },
                { id: 'MOMO', text: 'MOMO' },
            ],
        };
    },
    filters: {
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: this.elements.filterItem
            };
        }
    },
    created() {
        this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    methods: {
        getElementsList(pageNumber) {
            this.showLoading();
            gpuHubTransactionApi.getListRefundTransaction(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            this.$nextTick(() => {
                $("#detailModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },
        openAddRefundTransaction() {
            this.$nextTick(() => {
                $("#addRefundTransactionModal").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeAddRefundTransaction() {
            $("#addRefundTransactionModal").modal("hide");
            this.$set(this, "refundModel", null);
        },
    }
}